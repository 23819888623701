import React from "react";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Layout from "../components/layout";

const paraOne = {
    backgroundImage: 'url(../recu3TY5Ry5XQEPj7.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraTwo = {
    backgroundImage: 'url(../recrnUt6gQ8VqptJG.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraThree = {
    backgroundImage: 'url(../rect2BQgvlC4FEKsf.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraFour = {
    backgroundImage: 'url(../recj1uGoNS0IExf2A.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraFive = {
    backgroundImage: 'url(../recknC44NoCKNKKCg.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraSix = {
    backgroundImage: 'url(../rec2fV01WDmnc6HXB.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraSeven = {
    backgroundImage: 'url(../recd0jwEYd9MN2qR1.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraEight = {
    backgroundImage: 'url(../recXQe4oibLjcObhm.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraNine = {
    backgroundImage: 'url(../recryXT8UV1DV9rgA.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraTen = {
    backgroundImage: 'url(../recA1dF6Osb0sNGdv.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraEleven = {
    backgroundImage: 'url(../recgosnR9HoKAsChb.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const credit = {
    background: 'linear-gradient(rgba(255, 184, 29, 0.45), rgba(255, 184, 29, 0.45)), url(../recvjDpnbcbpKfe8S.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: '50% 70%',
    height: '50vh'
};

export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>{data.meta.data.story} | Oberlin Photo Stories</title>
            
            <meta name="title" content={data.meta.data.story} />
            <meta name="description" content={data.meta.data.description} />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data.meta.data.metaurl} />
            <meta property="og:title" content={data.meta.data.story} />
            <meta property="og:description" content={data.meta.data.description} />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/recXQe4oibLjcObhm.jpg" />
            <meta property="og:image:width" content="2000" />
            <meta property="og:image:height" content="1333" />
            <meta property="og:image:alt" content={data.meta.data.alt} />
            <meta property="fb:app_id" content="1571317633107125" />
            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={data.meta.data.metaurl} />
            <meta property="twitter:title" content={data.meta.data.story} />
            <meta property="twitter:description" content={data.meta.data.description} />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/recXQe4oibLjcObhm.jpg" />
            <meta property="twitter:image:alt" content={data.meta.data.alt} />
        </Helmet>
        {/* hero */}
        <header>
        <div className="carousel slide carousel-fade" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-interval="3000">
                        <Img fluid={data.recaxlPZyVpwJDpRw.data.file.localFiles[0].childImageSharp.fluid} alt={data.recaxlPZyVpwJDpRw.data.alt} className="hero" />
                        <div className="title default text-center">
                        <h1 className="display-3 text-light">{data.title.data.title}</h1>
                        <p className="lead text-light">- {data.subtitle.data.title} -</p>
                        <a href="#ss" aria-label="Skip to main content" tabIndex="0"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                        </div>
                    </div>
                    <div className="carousel-item" data-interval="3000">
                        <Img fluid={data.recIZvAgTOqC9d7oj.data.file.localFiles[0].childImageSharp.fluid} alt={data.recIZvAgTOqC9d7oj.data.alt} className="hero" />
                        <div className="title default text-center">
                        <h1 className="display-3 text-light">{data.title.data.title}</h1>
                        <p className="lead text-light">- {data.subtitle.data.title} -</p>
                        <a href="#ss" aria-label="Skip to main content" tabIndex="0"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                        </div>
                    </div>
                    <div className="carousel-item" data-interval="3000">
                        <Img fluid={data.recVs8bkjE9ovTJCT.data.file.localFiles[0].childImageSharp.fluid} alt={data.recVs8bkjE9ovTJCT.data.alt} className="hero" />
                        <div className="title default text-center">
                        <h1 className="display-3 text-light">{data.title.data.title}</h1>
                        <p className="lead text-light">- {data.subtitle.data.title} -</p>
                        <a href="#ss" aria-label="Skip to main content" tabIndex="0"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                        </div>
                    </div>
                    <div className="carousel-item" data-interval="3000">
                        <Img fluid={data.rect0CEFYWsE4Eu41.data.file.localFiles[0].childImageSharp.fluid} alt={data.rect0CEFYWsE4Eu41.data.alt} className="hero" />
                        <div className="title default text-center">
                        <h1 className="display-3 text-light">{data.title.data.title}</h1>
                        <p className="lead text-light">- {data.subtitle.data.title} -</p>
                        <a href="#ss" aria-label="Skip to main content" tabIndex="0"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <main>
            <div className="anchor" id="ss"></div>
            <div className="story">
                {/* 1.0 */}
                <section className="container my-5">
                    {/* intro */}
                    <Fade>
                        <div className="col-lg-10 text-center mx-auto lead mt-5" dangerouslySetInnerHTML={{
                            __html: data.intro.data.text.childMarkdownRemark.html,
                            }} />
                    </Fade>
                    {/* quote */}
                    <Fade>
                        <blockquote className="my-5 pullquote">
                        <p className="mb-0">{data.recvreKk7lqW0Vm1d.data.title}</p>
                        <footer className="pullquote-footer">{data.recvreKk7lqW0Vm1d.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                        </blockquote>
                    </Fade>
                    {/* full-width */}
                    <Fade>
                        <Img fluid={data.recNHxsPJ3fDnqra1.data.file.localFiles[0].childImageSharp.fluid} alt={data.recNHxsPJ3fDnqra1.data.alt} />
                        <Img fluid={data.rec2j4mGbplmzDQkE.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec2j4mGbplmzDQkE.data.alt} className="mt-4" />
                        <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.rec2j4mGbplmzDQkE.data.text.childMarkdownRemark.html,
                        }} />
                    </Fade>
                </section>
                {/* 3.0 */}
                <section>
                    <div style={paraOne} role="img" aria-label={data.recu3TY5Ry5XQEPj7.data.alt} className="parallax"></div>
                </section>
                {/* 4.0 */}
                <section className="container my-5">
                    {/* quote */}
                    <Fade>
                    <blockquote className="my-5 pullquote">
                    <p className="mb-0">{data.rec6zRYbM4qvOa7xi.data.title}</p>
                    <footer className="pullquote-footer">{data.rec6zRYbM4qvOa7xi.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                    </blockquote>
                    <Img fluid={data.reccOep7tuEcBGLTN.data.file.localFiles[0].childImageSharp.fluid} alt={data.reccOep7tuEcBGLTN.data.alt} />
                    </Fade>
                </section>
                {/* 5.0 */}
                <section className="container my-5">
                    {/* heading */}
                    <h2>{data.recNl1SpRc3HzNI4j.data.title}</h2>
                    {/* side-by-side */}
                    <div className="row">
                        <div className="col-lg col-12">
                            <Slide left>
                            <div>
                                <Img fixed={data.rec0KybaqTUSexRuD.data.file.localFiles[0].childImageSharp.fixed} alt={data.rec0KybaqTUSexRuD.data.alt} className="w-100" />
                            </div>
                            </Slide>
                            <Fade>
                            <div className="story">
                                <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                                __html: data.rec0KybaqTUSexRuD.data.text.childMarkdownRemark.html,
                                }} />
                            </div>
                            </Fade>
                        </div>
                        <div className="col-lg col-12">
                            <Slide right>
                            <div>
                                <Img fixed={data.rechSchOZD3cyzKiO.data.file.localFiles[0].childImageSharp.fixed} alt={data.rechSchOZD3cyzKiO.data.alt} className="w-100" />
                            </div>
                            </Slide>
                            <Fade>
                            <div className="story">
                                <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                                __html: data.rechSchOZD3cyzKiO.data.text.childMarkdownRemark.html,
                                }} />
                            </div>
                            </Fade>
                        </div>
                    </div>
                </section>
                {/* 6.0 */}
                <section>
                    <div style={paraTwo} role="img" aria-label={data.recrnUt6gQ8VqptJG.data.alt} className="parallax"></div>
                </section>
                {/* 7.0 */}
                <section className="container my-5">
                    <Fade>
                    <Img fluid={data.recwAcEgAsTtej0Rl.data.file.localFiles[0].childImageSharp.fluid} alt={data.recwAcEgAsTtej0Rl.data.alt} />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recwAcEgAsTtej0Rl.data.text.childMarkdownRemark.html,
                    }} />
                    </Fade>
                    <h2>{data.recOcTQa5vTvgcwPH.data.title}</h2>
                    <Fade>
                    <Img fluid={data.recXxHefC8cr8GaIj.data.file.localFiles[0].childImageSharp.fluid} alt={data.recXxHefC8cr8GaIj.data.alt} />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recXxHefC8cr8GaIj.data.text.childMarkdownRemark.html,
                    }} />
                    <blockquote className="my-5 pullquote">
                    <p className="mb-0">{data.recEyLuZA2o4Qv375.data.title}</p>
                    <footer className="pullquote-footer">{data.recEyLuZA2o4Qv375.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                    </blockquote>
                    <Img fluid={data.recp4DSiismaJbLrn.data.file.localFiles[0].childImageSharp.fluid} alt={data.recp4DSiismaJbLrn.data.alt} />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recp4DSiismaJbLrn.data.text.childMarkdownRemark.html,
                    }} />
                    <Img fluid={data.recnKglFAQF44xr5E.data.file.localFiles[0].childImageSharp.fluid} alt={data.recnKglFAQF44xr5E.data.alt} />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recnKglFAQF44xr5E.data.text.childMarkdownRemark.html,
                    }} />
                    <Img fluid={data.recMVkgwG5E41jhMR.data.file.localFiles[0].childImageSharp.fluid} alt={data.recMVkgwG5E41jhMR.data.alt} />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recMVkgwG5E41jhMR.data.text.childMarkdownRemark.html,
                    }} />
                    </Fade>
                </section>
                {/* 8.0 */}
                <section>
                    <div style={paraThree} role="img" aria-label={data.rect2BQgvlC4FEKsf.data.alt} className="parallax"></div>
                </section>
                {/* 9.0 */}
                <section className="container my-5">
                    <Fade>
                        <Img fluid={data.recvI7qitCLsbTLbO.data.file.localFiles[0].childImageSharp.fluid} alt={data.recvI7qitCLsbTLbO.data.alt} />
                        <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.recvI7qitCLsbTLbO.data.text.childMarkdownRemark.html,
                        }} />
                        <Img fluid={data.recBJKzf52XM95rQd.data.file.localFiles[0].childImageSharp.fluid} alt={data.recBJKzf52XM95rQd.data.alt} />
                        <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.recBJKzf52XM95rQd.data.text.childMarkdownRemark.html,
                        }} />
                    </Fade>
                </section>
                {/* 9.5 */}
                <section>
                    <div style={paraSeven} role="img" aria-label={data.recd0jwEYd9MN2qR1.data.alt} className="parallax"></div>
                </section>
                {/* 10.0 */}
                <section className="container my-5">
                    {/* heading */}
                    <h2>{data.rec4ct65LGtSeTt7X.data.title}</h2>
                    {/* quote */}
                    <Fade>
                    <blockquote className="my-5 pullquote">
                    <p className="mb-0">{data.recLfYYv0tX2Wo8MT.data.title}</p>
                    <footer className="pullquote-footer">{data.recLfYYv0tX2Wo8MT.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                    </blockquote>
                    </Fade>
                    {/* full-width */}
                    <Fade>
                        <Img fluid={data.recawQaxeegF4fzoA.data.file.localFiles[0].childImageSharp.fluid} alt={data.recawQaxeegF4fzoA.data.alt} className="mb-5" />
                        <Img fluid={data.recK7HmVMiFxR47Hf.data.file.localFiles[0].childImageSharp.fluid} alt={data.recK7HmVMiFxR47Hf.data.alt} className="mb-5" />
                        <Img fluid={data.recVzJ1p6gEKflpAU.data.file.localFiles[0].childImageSharp.fluid} alt={data.recVzJ1p6gEKflpAU.data.alt} className="mb-5" />
                        <Img fluid={data.recHPOx0Z0Iv5golw.data.file.localFiles[0].childImageSharp.fluid} alt={data.recHPOx0Z0Iv5golw.data.alt} className="mb-5" />
                    </Fade>
                </section>
                {/*  */}
                <section>
                    <div style={paraEight} role="img" aria-label={data.recvjDpnbcbpKfe8S.data.alt} className="parallax"></div>
                </section>
                {/*  */}
                <section className="container my-5">
                <h2>{data.rec7aTXrO8fM5LNmD.data.title}</h2>
                    <Fade>
                    <Img fluid={data.rec5qfLSpd7kUte7M.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec5qfLSpd7kUte7M.data.alt} />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec5qfLSpd7kUte7M.data.text.childMarkdownRemark.html,
                    }} />
                    <Img fluid={data.recibLSHaqxKzjbHA.data.file.localFiles[0].childImageSharp.fluid} alt={data.recibLSHaqxKzjbHA.data.alt} />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recibLSHaqxKzjbHA.data.text.childMarkdownRemark.html,
                    }} />
                    <Img fluid={data.rec1XutilpSuJBVep.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec1XutilpSuJBVep.data.alt} />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec1XutilpSuJBVep.data.text.childMarkdownRemark.html,
                    }} />
                    </Fade>
                </section>
                {/* 11.0 */}
                <section>
                <div style={paraFour} role="img" aria-label={data.recj1uGoNS0IExf2A.data.alt} className="parallax"></div>
                </section>
                {/* 12.0 */}
                <section className="container my-5">
                <h2>{data.recH1GnfioKh7kEHd.data.title}</h2>
                    {/* full-width */}
                    <Fade>
                        <Img fluid={data.recygJsNw0AMJyQcU.data.file.localFiles[0].childImageSharp.fluid} alt={data.recygJsNw0AMJyQcU.data.alt} />
                        <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.recygJsNw0AMJyQcU.data.text.childMarkdownRemark.html,
                        }} />
                        <Img fluid={data.reckiRf65zDUYbvmN.data.file.localFiles[0].childImageSharp.fluid} alt={data.reckiRf65zDUYbvmN.data.alt} />
                        <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.reckiRf65zDUYbvmN.data.text.childMarkdownRemark.html,
                        }} />
                        <Img fluid={data.recXh0F3Az6gydWqD.data.file.localFiles[0].childImageSharp.fluid} alt={data.recXh0F3Az6gydWqD.data.alt} />
                        <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.recXh0F3Az6gydWqD.data.text.childMarkdownRemark.html,
                        }} />
                        <Img fluid={data.recRNNBx5q3lDDKIv.data.file.localFiles[0].childImageSharp.fluid} alt={data.recRNNBx5q3lDDKIv.data.alt} />
                        <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.recRNNBx5q3lDDKIv.data.text.childMarkdownRemark.html,
                        }} />
                    </Fade>
                    {/* quote */}
                    <Fade>
                    <blockquote className="my-5 pullquote">
                    <p className="mb-0">{data.recfzYGmQxrmIN4l3.data.title}</p>
                    <footer className="pullquote-footer">{data.recfzYGmQxrmIN4l3.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                    </blockquote>
                    </Fade>
                    {/* full-width */}
                    <Fade>
                        <Img fluid={data.rech0rtkQaQ2TUXlQ.data.file.localFiles[0].childImageSharp.fluid} alt={data.rech0rtkQaQ2TUXlQ.data.alt} className="mb-5" />
                        <Img fluid={data.recEI5Z8rYNv7jtIR.data.file.localFiles[0].childImageSharp.fluid} alt={data.recEI5Z8rYNv7jtIR.data.alt} />
                        <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.recEI5Z8rYNv7jtIR.data.text.childMarkdownRemark.html,
                        }} />
                    </Fade>
                </section>
                {/* 13.0 */}
                <section>
                <div style={paraFive} role="img" aria-label={data.recknC44NoCKNKKCg.data.alt} className="parallax"></div>
                </section>
                {/* 14.0 */}
                <section className="container my-5">
                    <Fade>
                        <Img fluid={data.reckfs3TL3rHGL9nJ.data.file.localFiles[0].childImageSharp.fluid} alt={data.reckfs3TL3rHGL9nJ.data.alt} />
                        <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.reckfs3TL3rHGL9nJ.data.text.childMarkdownRemark.html,
                        }} />
                        <Img fluid={data.rec5L76vqaoMLN5lt.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec5L76vqaoMLN5lt.data.alt} />
                        <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.rec5L76vqaoMLN5lt.data.text.childMarkdownRemark.html,
                        }} />
                    </Fade>
                </section>
                {/* 15.0 */}
                <section>
                <div style={paraSix} role="img" aria-label={data.rec2fV01WDmnc6HXB.data.alt} className="parallax"></div>
                </section>
                {/* 16.0 */}
                <section className="container my-5">
                <h2>{data.rec4iba9vJOVmhP0J.data.title}</h2>
                    {/* full-width */}
                    <Fade>
                        <Img fluid={data.rec3rxuQSQ8u8c3H9.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec3rxuQSQ8u8c3H9.data.alt} />
                        <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.rec3rxuQSQ8u8c3H9.data.text.childMarkdownRemark.html,
                        }} />
                        <Img fluid={data.recmdjjvWDrNvhK0N.data.file.localFiles[0].childImageSharp.fluid} alt={data.recmdjjvWDrNvhK0N.data.alt} />
                        <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: data.recmdjjvWDrNvhK0N.data.text.childMarkdownRemark.html,
                        }} />
                    </Fade>
                    {/* three images */}
                    <div className="row mt-3">
                        <Fade>
                        <div className="col-lg-4 col-12 mb-3">
                            <Img fixed={data.recY3n6B6W2CGaL2i.data.file.localFiles[0].childImageSharp.fixed} alt={data.recY3n6B6W2CGaL2i.data.alt} className="w-100" />
                            <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                                __html: data.recY3n6B6W2CGaL2i.data.text.childMarkdownRemark.html,
                                }} />
                        </div>
                        <div className="col-lg-4 col-12 mb-3">
                            <Img fixed={data.recBByjkYh94An3lx.data.file.localFiles[0].childImageSharp.fixed} alt={data.recBByjkYh94An3lx.data.alt} className="w-100" />
                            <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                                __html: data.recBByjkYh94An3lx.data.text.childMarkdownRemark.html,
                                }} />
                        </div>
                        <div className="col-lg-4 col-12 mb-3">
                            <Img fixed={data.rec1MFbX83CAWyGiH.data.file.localFiles[0].childImageSharp.fixed} alt={data.rec1MFbX83CAWyGiH.data.alt} className="w-100" />
                            <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                                __html: data.rec1MFbX83CAWyGiH.data.text.childMarkdownRemark.html,
                                }} />
                        </div>
                        </Fade>
                    </div>
                    {/* full-width */}
                    <Fade>
                    <Img fluid={data.rec4dusikGZWW1Oqc.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec4dusikGZWW1Oqc.data.alt} />
                    <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec4dusikGZWW1Oqc.data.text.childMarkdownRemark.html,
                    }} />
                    </Fade>
                </section>
                {/*  */}
                <section>
                <div style={paraNine} role="img" aria-label={data.recryXT8UV1DV9rgA.data.alt} className="parallax"></div>
                </section>
                {/*  */}
                <section className="container my-5">
                    <Fade>
                    <Img fluid={data.rec0JWXF0jiEBGtBo.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec0JWXF0jiEBGtBo.data.alt} />
                    <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec0JWXF0jiEBGtBo.data.text.childMarkdownRemark.html,
                    }} />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recFKNQE10zsf0p18.data.file.localFiles[0].childImageSharp.fluid} alt={data.recFKNQE10zsf0p18.data.alt} />
                    <div  className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recFKNQE10zsf0p18.data.text.childMarkdownRemark.html,
                    }} />
                    </Fade>
                </section>
                {/*  */}
                <section>
                <div style={paraTen} role="img" aria-label={data.recA1dF6Osb0sNGdv.data.alt} className="parallax"></div>
                <div style={paraEleven} role="img" aria-label={data.recgosnR9HoKAsChb.data.alt} className="parallax"></div>
                </section>
                {/* credit */}
                <section>
                    <div style={credit} role="img" aria-label={data.recXQe4oibLjcObhm.data.alt} className="py-5 mb-0 text-center">
                        <div className="credit-text">
                            <p className="h4 credit-title">{data.recXQe4oibLjcObhm.data.title}</p>
                            <div dangerouslySetInnerHTML={{
                            __html: data.recXQe4oibLjcObhm.data.text.childMarkdownRemark.html,
                            }} style={{fontSize:'90%'}} />
                        </div>
                    </div>
                </section>
            </div>
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
    meta: airtable(recordId: {eq: "recIZvAgTOqC9d7oj"}) {
        data {
          file {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          story
          alt
          metaurl
          description
        }
      }

    title: airtable(data: {identifier: {eq: "recFxwJyKXhHJD3HA"}}) {
        data {
            title
        }
    }

    subtitle: airtable(data: {identifier: {eq: "recGZEC7UmW54MjPv"}}) {
        data {
            title
        }
    }

    recaxlPZyVpwJDpRw: airtable(data: {identifier: {eq: "recaxlPZyVpwJDpRw"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(fit: COVER, quality: 90, traceSVG: {color: "#EFE2C2", threshold: 200}) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
            alt
        }
    }

    recIZvAgTOqC9d7oj: airtable(data: {identifier: {eq: "recIZvAgTOqC9d7oj"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(fit: COVER, quality: 90, traceSVG: {color: "#EFE2C2", threshold: 200}) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
            alt
        }
    }

    recVs8bkjE9ovTJCT: airtable(data: {identifier: {eq: "recVs8bkjE9ovTJCT"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(fit: COVER, quality: 90, traceSVG: {color: "#EFE2C2", threshold: 200}) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
            alt
        }
    }

    rect0CEFYWsE4Eu41: airtable(data: {identifier: {eq: "rect0CEFYWsE4Eu41"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(fit: COVER, quality: 90, traceSVG: {color: "#EFE2C2", threshold: 200}) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
            alt
        }
    }
    
    intro: airtable(data: {identifier: {eq: "recqj1yTa6816KGI8"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recvreKk7lqW0Vm1d: airtable(data: {identifier: {eq: "recvreKk7lqW0Vm1d"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recNHxsPJ3fDnqra1: airtable(data: {identifier: {eq: "recNHxsPJ3fDnqra1"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec2j4mGbplmzDQkE: airtable(data: {identifier: {eq: "rec2j4mGbplmzDQkE"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recu3TY5Ry5XQEPj7: airtable(recordId: {eq: "recu3TY5Ry5XQEPj7"}) {
        data {
            alt
        }
    }

    rec6zRYbM4qvOa7xi: airtable(data: {identifier: {eq: "rec6zRYbM4qvOa7xi"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    reccOep7tuEcBGLTN: airtable(data: {identifier: {eq: "reccOep7tuEcBGLTN"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recNl1SpRc3HzNI4j: airtable(data: {identifier: {eq: "recNl1SpRc3HzNI4j"}}) {
        data {
            title
        }
    }

    rec0KybaqTUSexRuD: airtable(recordId: {eq: "rec0KybaqTUSexRuD"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rechSchOZD3cyzKiO: airtable(recordId: {eq: "rechSchOZD3cyzKiO"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recrnUt6gQ8VqptJG: airtable(recordId: {eq: "recrnUt6gQ8VqptJG"}) {
        data {
            alt
        }
    }

    recwAcEgAsTtej0Rl: airtable(data: {identifier: {eq: "recwAcEgAsTtej0Rl"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recOcTQa5vTvgcwPH: airtable(data: {identifier: {eq: "recOcTQa5vTvgcwPH"}}) {
        data {
            title
        }
    }
    
    recXxHefC8cr8GaIj: airtable(data: {identifier: {eq: "recXxHefC8cr8GaIj"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recEyLuZA2o4Qv375: airtable(data: {identifier: {eq: "recEyLuZA2o4Qv375"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recp4DSiismaJbLrn: airtable(data: {identifier: {eq: "recp4DSiismaJbLrn"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recnKglFAQF44xr5E: airtable(data: {identifier: {eq: "recnKglFAQF44xr5E"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recMVkgwG5E41jhMR: airtable(data: {identifier: {eq: "recMVkgwG5E41jhMR"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rect2BQgvlC4FEKsf: airtable(recordId: {eq: "rect2BQgvlC4FEKsf"}) {
        data {
            alt
        }
    }

    recvI7qitCLsbTLbO: airtable(data: {identifier: {eq: "recvI7qitCLsbTLbO"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recBJKzf52XM95rQd: airtable(data: {identifier: {eq: "recBJKzf52XM95rQd"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recd0jwEYd9MN2qR1: airtable(recordId: {eq: "recd0jwEYd9MN2qR1"}) {
        data {
            alt
        }
    }

    rec4ct65LGtSeTt7X: airtable(data: {identifier: {eq: "rec4ct65LGtSeTt7X"}}) {
        data {
            title
        }
    }

    recLfYYv0tX2Wo8MT: airtable(data: {identifier: {eq: "recLfYYv0tX2Wo8MT"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recawQaxeegF4fzoA: airtable(data: {identifier: {eq: "recawQaxeegF4fzoA"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recK7HmVMiFxR47Hf: airtable(data: {identifier: {eq: "recK7HmVMiFxR47Hf"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recVzJ1p6gEKflpAU: airtable(data: {identifier: {eq: "recVzJ1p6gEKflpAU"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recHPOx0Z0Iv5golw: airtable(data: {identifier: {eq: "recHPOx0Z0Iv5golw"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recvjDpnbcbpKfe8S: airtable(recordId: {eq: "recvjDpnbcbpKfe8S"}) {
        data {
            alt
        }
    }

    rec7aTXrO8fM5LNmD: airtable(data: {identifier: {eq: "rec7aTXrO8fM5LNmD"}}) {
        data {
            title
        }
    }

    rec5qfLSpd7kUte7M: airtable(data: {identifier: {eq: "rec5qfLSpd7kUte7M"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recibLSHaqxKzjbHA: airtable(data: {identifier: {eq: "recibLSHaqxKzjbHA"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec1XutilpSuJBVep: airtable(data: {identifier: {eq: "rec1XutilpSuJBVep"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recj1uGoNS0IExf2A: airtable(recordId: {eq: "recj1uGoNS0IExf2A"}) {
        data {
            alt
        }
    }

    recH1GnfioKh7kEHd: airtable(data: {identifier: {eq: "recH1GnfioKh7kEHd"}}) {
        data {
            title
        }
    }

    recygJsNw0AMJyQcU: airtable(data: {identifier: {eq: "recygJsNw0AMJyQcU"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reckiRf65zDUYbvmN: airtable(data: {identifier: {eq: "reckiRf65zDUYbvmN"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recXh0F3Az6gydWqD: airtable(data: {identifier: {eq: "recXh0F3Az6gydWqD"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recRNNBx5q3lDDKIv: airtable(data: {identifier: {eq: "recRNNBx5q3lDDKIv"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recfzYGmQxrmIN4l3: airtable(data: {identifier: {eq: "recfzYGmQxrmIN4l3"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    rech0rtkQaQ2TUXlQ: airtable(data: {identifier: {eq: "rech0rtkQaQ2TUXlQ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recEI5Z8rYNv7jtIR: airtable(data: {identifier: {eq: "recEI5Z8rYNv7jtIR"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recknC44NoCKNKKCg: airtable(recordId: {eq: "recknC44NoCKNKKCg"}) {
        data {
            alt
        }
    }

    reckfs3TL3rHGL9nJ: airtable(data: {identifier: {eq: "reckfs3TL3rHGL9nJ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec5L76vqaoMLN5lt: airtable(data: {identifier: {eq: "rec5L76vqaoMLN5lt"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec2fV01WDmnc6HXB: airtable(recordId: {eq: "rec2fV01WDmnc6HXB"}) {
        data {
            alt
        }
    }

    rec3rxuQSQ8u8c3H9: airtable(data: {identifier: {eq: "rec3rxuQSQ8u8c3H9"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec4iba9vJOVmhP0J: airtable(data: {identifier: {eq: "rec4iba9vJOVmhP0J"}}) {
        data {
            title
        }
    }

    recmdjjvWDrNvhK0N: airtable(data: {identifier: {eq: "recmdjjvWDrNvhK0N"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recY3n6B6W2CGaL2i: airtable(recordId: {eq: "recY3n6B6W2CGaL2i"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recBByjkYh94An3lx: airtable(recordId: {eq: "recBByjkYh94An3lx"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec1MFbX83CAWyGiH: airtable(recordId: {eq: "rec1MFbX83CAWyGiH"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec4dusikGZWW1Oqc: airtable(data: {identifier: {eq: "rec4dusikGZWW1Oqc"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recryXT8UV1DV9rgA: airtable(recordId: {eq: "recryXT8UV1DV9rgA"}) {
        data {
            alt
        }
    }

    rec0JWXF0jiEBGtBo: airtable(data: {identifier: {eq: "rec0JWXF0jiEBGtBo"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recFKNQE10zsf0p18: airtable(data: {identifier: {eq: "recFKNQE10zsf0p18"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recA1dF6Osb0sNGdv: airtable(recordId: {eq: "recA1dF6Osb0sNGdv"}) {
        data {
            alt
        }
    }

    recgosnR9HoKAsChb: airtable(recordId: {eq: "recgosnR9HoKAsChb"}) {
        data {
            alt
        }
    }

    recXQe4oibLjcObhm: airtable(recordId: {eq: "recXQe4oibLjcObhm"}) {
        data {
            alt
            title
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
}  
`;